<template>
  <div>
    <form-wizard
      color="#2a6f39"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Enregistrer"
      back-button-text="Precedent"
      next-button-text="Suivant"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >

      <!-- account datails tab -->
      <tab-content title="INFORMATION PDV">
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          />
          <b-col md="12">
            <b-form-group
              label="PDV"
              label-for="v-country"
            >
              <v-select
                id="v-country"
                v-model="visite.pdv"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pdv"
                label="nom"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card
              no-body
              class="border mt-1"
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <span class="align-middle ml-50">DISPONIBILITÉ DU PLV</span>
                </b-card-title>
              </b-card-header>
              <b-table
                striped
                responsive
                class="mb-0"
                :items="visite.plv_data"
              >
                <template #cell(PLV)="data">
                  {{ data.value }}
                </template>
                <template #cell()="data">
                  <b-form-checkbox v-model="data.item.DISPONIBILITÉ" />
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <!-- personal info tab -->
      <tab-content title="DISPO - JUS DE FRUIT">
        <b-row>
          <b-col>
            <b-card
              no-body
              class="border mt-1"
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <span class="align-middle ml-50"> DISPONIBILITÉ JUS DE FRUIT MALIA </span>
                </b-card-title>
              </b-card-header>
              <!--              <b-table-->
              <!--                striped-->
              <!--                responsive-->
              <!--                class="mb-0"-->
              <!--                :items="visite.jus_afridia_data"-->
              <!--              >-->
              <!--                <template #cell(MALIA)="data">-->
              <!--                  {{ data.value }}-->
              <!--                </template>-->
              <!--                <template #cell()="data">-->
              <!--                  <b-form-checkbox :checked="data.value" />-->
              <!--                </template>-->
              <!--              </b-table>-->
              <b-table
                responsive
                :items="visite.jus_afridia_data"
                :fields="fields"
                class="mb-0"
              >
                <template v-slot:cell(Produit)="data">
                  <span class="text-nowrap">
                    <strong>{{ data.item.nom_produit }}</strong>
                  </span>
                </template>
                <template v-slot:cell(Visite-Prix)="data">
                  <b-form-input
                    v-model="data.item.prix_new"
                    placeholder="Entrez le prix actuel"
                    style="width: 100%"
                    @input="evaluationPrix(data.item)"
                  />
                </template>
                <template v-slot:cell(Evaluation-Prix)="data">
                  <span class="text-nowrap">
                    <strong v-if="data.item.prix_eval==='Prix trop élevé'">
                      <b-badge variant="light-danger">
                        Prix trop élevé
                      </b-badge>
                      [{{ data.item.prix_max }}]
                    </strong>
                    <strong v-if="data.item.prix_eval==='Prix correct'">
                      <b-badge variant="light-success">
                        Prix correct
                      </b-badge>
                      [{{ data.item.prix_max }}]
                    </strong>
                  </span>
                </template>
                <template v-slot:cell(Visite-Quantité)="data">
                  <b-form-input
                    v-model="data.item.quantity_new"
                    placeholder="Entrez la quantité actuelle"
                    style="width: 100%"
                    @input="produitDisponibilite(data.item)"
                  />
                </template>
                <template v-slot:cell(Disponibilité)="data">
                  <span class="text-nowrap">
                    <strong v-if="data.item.dispo">
                      <b-badge variant="light-success">
                        Disponible
                      </b-badge>
                    </strong>
                    <strong v-else>
                      <b-badge variant="light-danger">
                        Non disponible
                      </b-badge>
                    </strong>
                  </span>
                </template>
              </b-table>

            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card
              no-body
              class="border mt-1"
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <span class="align-middle ml-50"> DISPONIBILITÉ JUS DE FRUIT PETIT MALIA</span>
                </b-card-title>
              </b-card-header>
              <b-table
                responsive
                :items="visite.jus_afridia_petit_data"
                :fields="fields"
                class="mb-0"
              >
                <template v-slot:cell(Produit)="data">
                  <span class="text-nowrap">
                    <strong>{{ data.item.nom_produit }}</strong>
                  </span>
                </template>
                <template v-slot:cell(Visite-Prix)="data">
                  <b-form-input
                    v-model="data.item.prix_new"
                    placeholder="Entrez le prix actuel"
                    style="width: 100%"
                    @input="evaluationPrix(data.item)"
                  />
                </template>
                <template v-slot:cell(Evaluation-Prix)="data">
                  <span class="text-nowrap">
                    <strong v-if="data.item.prix_eval==='Prix trop élevé'">
                      <b-badge variant="light-danger">
                        Prix trop élevé
                      </b-badge>
                      [{{ data.item.prix_max }}]
                    </strong>
                    <strong v-if="data.item.prix_eval==='Prix correct'">
                      <b-badge variant="light-success">
                        Prix correct
                      </b-badge>
                      [{{ data.item.prix_max }}]
                    </strong>
                  </span>
                </template>
                <template v-slot:cell(Visite-Quantité)="data">
                  <b-form-input
                    v-model="data.item.quantity_new"
                    placeholder="Entrez la quantité actuelle"
                    style="width: 100%"
                    @input="produitDisponibilite(data.item)"
                  />
                </template>
                <template v-slot:cell(Disponibilité)="data">
                  <span class="text-nowrap">
                    <strong v-if="data.item.dispo">
                      <b-badge variant="light-success">
                        Disponible
                      </b-badge>
                    </strong>
                    <strong v-else>
                      <b-badge variant="light-danger">
                        Non disponible
                      </b-badge>
                    </strong>
                  </span>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <!-- address -->
      <tab-content title="DISPO CONCURRENCE">
        <b-row>
          <b-col>
            <b-card
              no-body
              class="border mt-1"
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <span class="align-middle ml-50"> DISPONIBILITÉ PRODUITS CONCURRENTS</span>
                </b-card-title>
              </b-card-header>
              <b-table
                responsive
                :items="visite.jus_concurrents_data"
                :fields="fieldsConcurrence"
                class="mb-0"
              >
                <template v-slot:cell(Produit)="data">
                  <span class="text-nowrap">
                    <strong>{{ data.item.nom_produit }}</strong>
                  </span>
                </template>
                <template v-slot:cell(Visite-Prix)="data">
                  <b-form-input
                    v-model="data.item.prix_new"
                    placeholder="Entrez le prix actuel"
                    style="width: 100%"
                  />
                </template>
                <template v-slot:cell(Visite-Quantité)="data">
                  <b-form-input
                    v-model="data.item.quantity_new"
                    placeholder="Entrez la quantité actuelle"
                    style="width: 100%"
                    @input="produitDisponibilite(data.item)"
                  />
                </template>
                <template v-slot:cell(Disponibilité)="data">
                  <span class="text-nowrap">
                    <strong v-if="data.item.dispo">
                      <b-badge variant="light-success">
                        Disponible
                      </b-badge>
                    </strong>
                    <strong v-else>
                      <b-badge variant="light-danger">
                        Non disponible
                      </b-badge>
                    </strong>
                  </span>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <!-- social link -->
      <tab-content title="ACTIONS REALISES">
        <b-row>
          <b-col>
            <b-card
              no-body
              class="border mt-1"
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <span class="align-middle ml-50"> RESPECT MERCHANDISING FRIGO</span>
                </b-card-title>
              </b-card-header>
              <b-table
                striped
                responsive
                class="mb-0"
                :items="visite.merchandising"
              >
                <template #cell(ACTIONS)="data">
                  {{ data.value }}
                </template>
                <template #cell()="data">
                  <b-form-checkbox v-model="data.item.EFFECTUÉE" />
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card
              no-body
              class="border mt-1"
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <span class="align-middle ml-50"> ACTIONS REALISES</span>
                </b-card-title>
              </b-card-header>
              <b-table
                striped
                responsive
                class="mb-0"
                :items="visite.actions_realises"
              >
                <template #cell(ACTIONS)="data">
                  {{ data.value }}
                </template>
                <template #cell()="data">
                  <b-form-checkbox v-model="data.item.EFFECTUÉE" />
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput, BCardHeader, BCardTitle, BTable, BFormCheckbox, BCard, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { API_URL } from '@/helpers/global-scops'
import showToast from '@/helpers/methodes'

export default {
  components: {
    BBadge,
    BCard,
    BFormCheckbox,
    BTable,
    BCardTitle,
    BCardHeader,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {

      fields: [
        'Produit',
        'Visite-Prix',
        'Evaluation-Prix',
        'Visite-Quantité',
        'Disponibilité',
      ],
      fieldsConcurrence: [
        'Produit',
        'Visite-Prix',
        'Visite-Quantité',
        'Disponibilité',
      ],
      visite: {
        pdv: null,
        plv_data: [
          {
            PLV: 'Exterieure',
            DISPONIBILITÉ: false,
          },
          {
            PLV: 'Interireure',
            DISPONIBILITÉ: false,
          },
        ],
        jus_afridia_data: [],
        jus_afridia_petit_data: [],
        jus_concurrents_data: [],
        merchandising: [
          {
            ACTIONS: 'Respect Merchandising Frigo',
            EFFECTUÉE: false,
          },
        ],
        actions_realises: [],

      },
      selectedContry: 'Selectionner un PDV',
      selectedLanguage: 'nothing_selected',
      pdv: [
        {
          value: 'select_value',
          text: 'Selectionner un PDV',
        },
      ],
      languageName: [
        {
          value: 'nothing_selected',
          text: 'Nothing Selected',
        },
        {
          value: 'English',
          text: 'English',
        },
        {
          value: 'Chinese',
          text: 'Mandarin Chinese',
        },
        {
          value: 'Hindi',
          text: 'Hindi',
        },
        {
          value: 'Spanish',
          text: 'Spanish',
        },
        {
          value: 'Arabic',
          text: 'Arabic',
        },
        {
          value: 'Malay',
          text: 'Malay',
        },
        {
          value: 'Russian',
          text: 'Russian',
        },
      ],
    }
  },
  setup() {
    return {
      ToastificationContent,
    }
  },
  created() {
    this.getPDV()
    this.getProduits()
    this.getProduitsConcurrent()
    this.getActions()
    this.visite.tourner_id = this.$route.params.tournee_id
    this.visite.commercial_id = this.$route.params.commercial_id
  },
  methods: {
    formSubmitted() {
      console.log(this.visite)
      this.$http.post(`${API_URL}visites/`, this.visite)
        .then(() => {
          showToast(this, 'success', 'Ajout', 'Element ajouté')
          this.$router.push({ name: 'tourners-details', params: { id: this.$route.params.tournee_id } })
        })
        .catch(error => {
          console.log(error)
        })
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: 'Form Submitted',
      //     icon: 'EditIcon',
      //     variant: 'success',
      //   },
      // })
    },
    getPDV() {
      this.$http.get(`${API_URL}point_de_ventes/?commercial=${this.$route.params.commercial_id}&tournee=${this.$route.params.tournee_id}`)
        .then(response => {
          this.pdv = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getProduits() {
      this.$http.get(`${API_URL}produits-afridia/`)
        .then(response => {
          const produits = response.data
          produits.forEach(produit => {
            if (produit.type === 'MALIA') {
              // this.visite.jus_afridia_data.push({
              //   MALIA: produit.nom_produit,
              //   DISPONIBILITÉ: false,
              // })
              this.visite.jus_afridia_data.push(produit)
            }
            if (produit.type === 'PETIT MALIA') {
              // this.visite.jus_afridia_petit_data.push({
              //   PETITMALIA: produit.nom_produit,
              //   DISPONIBILITÉ: false,
              // })
              this.visite.jus_afridia_petit_data.push(produit)
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    getProduitsConcurrent() {
      this.$http.get(`${API_URL}produit_concurrences/`)
        .then(response => {
          const produits = response.data
          produits.forEach(produit => {
            this.visite.jus_concurrents_data.push(produit)
            // this.visite.jus_concurrents_data.push({
            //   JUS: produit.nom_produit,
            //   DISPONIBILITÉ: false,
            // })
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    produitDisponibilite(item) {
      let response = false
      // eslint-disable-next-line radix
      response = parseInt(item.quantity_new) >= 0

      // eslint-disable-next-line no-param-reassign
      item.dispo = response
      return response
    },
    evaluationPrix(item) {
      // eslint-disable-next-line radix
      if (parseInt(item.prix_new) > parseInt(item.prix_max)) {
        // eslint-disable-next-line no-param-reassign
        item.prix_eval = 'Prix trop élevé'
      } else {
        // eslint-disable-next-line no-param-reassign
        item.prix_eval = 'Prix correct'
      }
      return item.prix_eval
    },
    getActions() {
      this.$http.get(`${API_URL}action_sa_realisers/`)
        .then(response => {
          const actions = response.data
          actions.forEach(action => {
            this.visite.actions_realises.push(
              {
                ACTIONS: action.nom,
                EFFECTUÉE: false,
              },
            )
          })
        })
        .catch(error => {
          console.log(error)
        })
    },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
